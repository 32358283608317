import * as React from "react"
import TermsLayout from "../templates/terms-layout"

const Terms = ({ location }) => {
	return (
		<TermsLayout location={location}>
			<p>We take the security of your data very seriously at Exploo. As transparency is one of the principles on which our company is built, we aim to be as clear and open as we can about the way that we handle security. If you have additional questions regarding security, we’d be happy to answer them. Please write to feedback@exploo.com and we will respond as quickly as we can.</p>
			<h2>Platform controls</h2>
			<h3>Architecture and data segregation</h3>
			<p>The Exploo services are operated on a multi-tenant architecture at both the platform and infrastructure layers that is designed to segregate and restrict access to the data that you and your users make available via the Exploo services, as more specifically defined in your agreement with Exploo (or its corporate affiliate(s)) covering the use of the Exploo services (‘Customer Data’), based on business needs. The architecture provides a logical data separation for each different customer via a unique ID.</p>
			<h3>Public cloud infrastructure</h3>
			<p>The Exploo services are hosted over the Internet on a ‘Public Cloud’, which are computing services offered by third-party providers to anyone who wants to use or purchase them. Like all cloud services, a public cloud service runs on remote servers that a provider manages.</p>
			<h3>Audits</h3>
			<p>The Exploo services undergo security assessments by internal personnel and respected external security firms, who perform regular audits of the Exploo services to verify that our security practices are sound and to monitor the Exploo services for new vulnerabilities discovered by the security research community. In addition to periodic and targeted audits of the Exploo services and features, we also employ the use of continuous hybrid automated scanning of our web platform.</p>
			<h3>Security controls</h3>
			<p>Exploo will implement and maintain appropriate technical and organisational measures to protect your Customer Data against accidental or unlawful destruction, loss, alteration and unauthorised disclosure of or access to the Customer’s personal data that is processed or transmitted through the Exploo services. The Exploo services have a number of security controls, including but not limited to:</p>
			<ul>
				<li>Access management. Administrators can remotely terminate all team access rights at any time, on demand.</li>
				<li>Host management. We perform automated vulnerability scans on our production hosts and remediate any findings that present a risk to our environment.</li>
				<li>Network protection. In addition to sophisticated system monitoring and logging, we have implemented two-factor authentication for all server access across our production environment. Firewalls are configured according to industry best practices, using AWS security groups.</li>
				<li>Product security practices. New features, significant functionality and design changes go through a security review process facilitated by the security team. In addition, our code is audited with automated static analysis software, tested and manually peer reviewed prior to being deployed to production. The security team works closely with development teams to resolve any additional security concerns that may arise during development. Exploo also operates a security bug bounty programme. Security researchers around the world continuously test the security of the Exploo services, and report issues via the programme. More details of this programme are available at the bounty site.</li>
			</ul>
			<p>For some of the controls, the Customer cannot disable them; others provide customisation of the Exploo services’ security by Customers for their own use. As such, protecting Customer Data is a joint responsibility between the Customer and Exploo.</p>
			<h3>Intrusion detection</h3>
			<p>Exploo, or an authorised external entity, will monitor the Exploo services for unauthorised intrusions.</p>
			<h3>Security logs</h3>
			<p>Systems used in the provision of the Exploo services log information to their respective system log facilities or a centralised logging service (for network systems) in order to enable security reviews and analysis. Exploo maintains an extensive centralised logging environment in the production environment which contains information pertaining to security, monitoring, availability, access and other metrics about the Exploo services. These logs are analysed for security events via automated monitoring software, overseen by the security team.</p>
			<h3>Incident management</h3>
			<p>Exploo maintains security incident management policies and procedures. Exploo notifies impacted customers without undue delay of any unauthorised disclosure of their respective Customer Data by Exploo or its agents of which Exploo becomes aware to the extent permitted by law. Exploo publishes system status information on the Exploo System Status page. Exploo typically notifies customers of significant system incidents via email, and for incidents lasting for more than one hour, may invite affected customers to join a conference call about the incident and Exploo’s response.</p>
			<h3>Data encryption</h3>
			<p>The Exploo services use industry-accepted encryption products to protect Customer Data (1) during transmissions between a customer's network and the Exploo services; and (2) when at rest. The Exploo services support the latest recommended secure cypher suites and protocols to encrypt all traffic in transit. We monitor the changing cryptographic landscape closely and work promptly to upgrade the service to respond to new cryptographic weaknesses as they are discovered and implement best practices as they evolve. For encryption in transit, we do this while also balancing the need for compatibility with older clients.</p>
			<h3>Reliability, backup and business continuity</h3>
			<p>We understand that you rely on the Exploo services to work. We’re committed to making the Exploo services a highly available service that you can rely on. Our infrastructure runs on systems that are fault tolerant, for failures of individual servers or even entire data centres. Our operations team tests disaster-recovery measures regularly and has a 24-hour on-call team to quickly resolve unexpected incidents. Industry standard best practices for reliability and backup helped to shape the design of the Exploo services. Exploo performs regular backups, facilitates rollbacks of software and system changes when necessary and replication of data as needed. Where possible, Exploo will assist the Customer with data recovery for Major Catastrophic Events, as limited by data residency requirements of the locality and capabilities within the region. ‘Major Catastrophic Event’ means three broad types of occurrences: (1) natural events such as floods, hurricanes, tornadoes, earthquakes and epidemics; (2) technological events such as failures of systems and structures such as pipeline explosions, transportation accidents, utility disruptions, dam failures and accidental hazardous material releases; and (3) human-caused events such as active assailant attacks, chemical or biological attacks, cyberattacks against data or infrastructure, and sabotage. Major Catastrophic Events do not include bugs, operational issues or other common software-related errors.</p>
			<h3>Data at rest</h3>
			<p>Exploo will store Customer Data at rest within certain major geographic areas except as otherwise provided in your order form.</p>
			<h3>Return of Customer Data</h3>
			<p>Within 30 days post contract termination, customers may request return of their respective Customer Data submitted to the Exploo services (to the extent that such data has not been deleted by the Customer).</p>
			<h3>Deletion of Customer Data</h3>
			<p>The Exploo services provide the option for workspace primary owners to delete Customer Data at any time during a subscription term. Within 24 hours of deletion initiated by a workspace primary owner, Exploo hard deletes all information from currently running production systems (excluding team names and search terms embedded in URLs in web server access logs). Exploo services backups are destroyed within 14 days (except that during an ongoing investigation of an incident, this period may be temporarily extended).</p>
			<p>When a Customer terminates a paid subscription to Enterprise Grid, if a Customer does not otherwise elect to delete its account, Exploo will, within 90 days following the subscription termination, delete, and ensure that all of its Affiliates and applicable third-party hosting providers delete, all copies of Customer Data (excluding team names, and search terms embedded in URLs in web server access logs) within 14 days after Exploo has initiated deletion of the Customer's account. When a customer terminates any paid subscription to the Exploo services other than Enterprise Grid, the customer’s subscription will continue under the free usage tier for the Exploo services subject to the then-current online Customer Terms of Service or other main online subscription agreement applicable to such free usage tier (‘Free Subscription Terms’), and the Customer Data will not be deleted until (i) the Customer self-deletes the workspace, (ii) the Customer otherwise instructs Exploo to delete their Customer Data or (iii) either party terminates the Free Subscription Terms. Upon the occurrence of such events, Exploo shall, within 14 days, delete, and ensure that all of its Affiliates and the permitted third party hosting providers delete, all copies of Customer Data (excluding team names, and search terms embedded in URLs in web server access logs).</p>
			<h3>Confidentiality</h3>
			<p>We place strict controls over our employees’ access to Customer Data. The operation of the Exploo services requires that some employees have access to the systems which store and process Customer Data. For example, in order to diagnose a problem that you are having with the Exploo services, we may need to access your Customer Data. These employees are prohibited from using these permissions to view Customer Data unless it is necessary to do so. We have technical controls and audit policies in place to ensure that any access to Customer Data is logged.</p>
			<p>All our employees and contract personnel are bound to our policies regarding Customer Data, and we treat these issues as matters of the highest importance within our company.</p>
			<h3>Personnel practices</h3>
			<p>Exploo conducts background checks on all employees before employment, and employees receive privacy and security training during onboarding as well as on an ongoing basis. All employees are required to read and sign our comprehensive information security policy covering the security, availability and confidentiality of the Exploo services.</p>
			<h2>Infrastructure</h2>
			<p>Exploo uses infrastructure provided by Amazon Web Services, Inc. (‘AWS’), DigitalOcean, MongoDB Inc. and Twilio/SendGrid to host or process Customer Data submitted to the Exploo services. Information about security provided by AWS is available from the AWS Security website. Information about security and privacy-related audits and certifications received by AWS, including information on ISO 27001 certification and SOC reports, is available from the AWS Compliance website.</p>
		</TermsLayout>
	)
}

export default Terms